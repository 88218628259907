import React from 'react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

// Icons style
import './assets/styles.css'

// Language Translation
import './i18n'

import App from './browser/App'

const renderMethod = module.hot ? render : hydrate
renderMethod(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
