import React from 'react'
import * as ShellComponents from '@process/index'
import { Redirect, Route } from 'react-router-dom'
import { IBootloaderProps, PlatformRoute } from './types'

const generateRoutes = (props: IBootloaderProps): React.ReactElement[] => {
  const { routes, ...rest } = props

  if (!routes) return []

  return routes?.map((item: PlatformRoute, index: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const Page = ShellComponents[item.component_name] || null
    if (item.component_name === 'LoginShell') {
      return (
        <Route
          path={item.path}
          exact={item.exact}
          key={`${item.component_name}:${index}`}>
          <Page {...rest} />
        </Route>
      )
    }

    return (
      <Route
        path={item.path}
        exact={item.exact}
        key={`${item.component_name}:${index}`}>
        {item.redirect?.to ? (
          <Redirect to={item.redirect!.to} />
        ) : (
          Page && <Page {...rest} config={item.config} />
        )}
      </Route>
    )
  })
}

export default generateRoutes
