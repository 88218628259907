// PLEASE CHECK THE EXISTING CLASSES.
// IF IT EXIST, OVERRIDE IT.
// DON'T CREATE ANOTHER CLASS.

export const defaultThemes = {
  // Avatar Styles
  avatarStyles: {
    base: 'w-full',
    wrapper: 'flex flex-row items-center',
    img: 'pl-4',
    label: 'w-full pl-2',
    userName: 'text-lg font-medium',
    companyName: 'text-sm'
  },

  // AlertDialogStyle
  alertDialogStyle: {
    base: 'h-full w-full p-r1 bg-secondary bg-opacity-50 flex justify-center items-center absolute inset-0 z-30',
    contentCls: 'flex flex-col bg-white shadow-md space-y-r1 w-96',
    header: 'p-r.5 flex items-center justify-between border-b border-gray-100'
  },

  // Collapsible Menu
  collapsibleMenuStyles: {
    linkItem:
      'border-l-2 py-2 px-4 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75'
  },

  // Details Tab
  detailsTabStyles: {
    base: 'bg-white p-2'
  },

  // Dialog
  dialogStyles: {
    base: 'z-50 fixed -inset-0 w-full h-screen flex flex-col overflow-hidden',
    wrapper: 'relative w-full h-full flex flex-row bg-blue-100 overflow-hidden',
    content: 'w-full flex-1 h-full overflow-auto',
    container: 'w-full p-4'
  },

  // Edit Contacts
  higherTabs: {
    wrapperCls: 'w-full h-full overflow-hidden',
    baseCls: 'w-full h-full flex flex-row',
    summaryCls: 'w-full max-w-xs h-full',
    containerCls: 'flex-1 h-full overflow-auto',
    contentCls: 'w-full flex flex-col p-r.25',
    summary: {
      baseCls: 'w-full flex flex-col',
      primaryInfo: {
        base: 'w-full flex flex-col p-r.5 mb-r.5 space-y-r.5',
        imgWrapper: 'relative w-full h-auto p-r1 flex items-center justify-center',
        imgFloatingBg: 'absolute top-0 left-0 right-0 w-full h-20 bg-gradient-to-br from-secondary-50 to-primary-50',
        img: 'w-24 h-24 flex items-center justify-center',
        emptyImg: 'border border-secondary-100',
        content: 'w-full flex flex-col px-r.5 space-y-r.5',
        icon: 'mt-r.1',
        label: 'w-full flex items-start space-x-r.75 text_base_secondary-dark'
      },
      otherInfo: {
        base: 'w-full flex-1 flex flex-col p-r.5 space-y-r.5',
        title: 'text_base_secondary-dark_bold',
        itemWrapper: 'w-full flex items-center px-r.5 space-x-r.5',
        item: 'flex flex-col',
        label: 'text_base_secondary-dark'
      }
    },
    pagination: {
      baseCls: 'w-full flex flex-row items-center justify-between p-r.5',
      breadcrumbCls: 'flex items-center',
      homeCls: 'text_base_secondary-dark_bold',
      currentCls: 'text_base_primary_bold',
      iconCls: 'text_base_secondary-dark',
      actionsCls: 'flex items-center justify-end space-x-r.5',
      paginationCls: 'flex items-center',
      labelCls: 'text_base_secondary-dark'
    },
    toolbar: {
      baseCls: 'w-full flex p-r.5 space-x-r.5',
    },
    basicInfo: {
      baseCls: 'w-full p-r.5 grid grid-cols-12 gap-4',
      fieldCls: 'col-span-5',
      singleSelectCls: 'col-span-6'
    },
    contactInfo: {
      baseCls: 'w-full p-r.5 grid grid-cols-12 gap-4',
      columnCls: 'col-span-5',
      formCls: 'w-full flex flex-col space-y-r.5',
      item: {
        base: 'w-full flex items-baseline',
        radio: 'w-5 h-5 flex items-center justify-center',
        wrapper: 'relative flex-1 flex flex-col',
        input: 'flex-1',
        removeBtn: 'cursor-pointer outline-none absolute top-3 right-0 text-danger text-lg hover:text-danger-dark',
        addBtn: 'mt-r.5 ml-10',
      }
    },
    addressInfo: {
      baseCls: 'w-full p-r.5 grid grid-cols-12 gap-4',
      fieldCls: 'col-span-10'
    }
  },

  //Error Pages
  errorStyles: {
    base: 'w-full h-screen bg-blue-100 flex justify-center items-center flex-col',
    heading: 'text-9xl font-bold',
    subHeading: 'text-2xl',
    link: 'border border-gray-500 px-6 py-2 mt-6'
  },

  // CreateExtras
  addExtrasStyles: {
    base: 'shadow-md bg-white flex flex-col flex-wrap p-5 m-2',
    fieldContainer: 'relative my-4 flex-1 flex flex-row flex-wrap items-center',
    pill: 'flex inline-flex items-center bg-blue-500 py-1 px-2 mr-1 rounded-full text-white text-sm',
    input: 'flex-1 p-2 outline-none'
  },

  // Forms
  formStyles: {
    formGroup: 'w-full h-auto my-8',
    formField: 'w-full flex flex-col'
  },

  // Footer
  footerComponentStyles: {
    base: 'w-full h-14 px-3 bg-blue-200 border-t border-gray-300 flex justify-center items-center',
    text: 'text-gray-500 text-sm'
  },

  // Grid
  gridStyles: {
    base: 'w-full flex flex-col'
  },

  // Grid Details
  gridDetailStyle: {
    baseCls: 'w-full flex flex-col',
    itemCls: 'flex-1 flex items-baseline flex-row',
    labelCls: 'capitalize text-base text-secondary-dark p-1 w-auto break-words'
  },

  // HEADER
  headerSlotStyles: {
    baseCls:
      'w-full h-16 flex items-center bg-white border-b-4 border-primary z-50',
    headerLeftCls: 'w-64 h-full flex items-center justify-between',
    headerRightCls:
      'w-64 h-full py-2 pl-2 pr-4 space-x-4 flex items-center justify-end',
    headerCenterCls: 'flex-1 p-2 flex items-center justify-center',
    brandingLogoCls: 'w-24 h-full p-1 mr-auto flex items-center justify-center'
  },

  // LAYOUT
  layoutStyles: {
    baseCls: 'w-screen h-screen flex flex-col overflow-hidden',
    containerCls:
      'relative w-full h-full flex flex-row flex-nowrap bg-blue-100 overflow-hidden',
    contentAreaCls: 'relative w-full h-full flex-1 p-4 overflow-hidden'
  },

  // Login
  loginStyles: {
    baseCls: 'w-full h-screen bg-blue-100 flex flex-row overflow-hidden',
    leftPanelCls:
      'w-full max-w-xl h-full bg-white flex flex-col justify-center',
    leftPanelContainerCls:
      'w-full h-full p-6 flex flex-col justify-start items-center',
    companyLogoCls: 'w-full flex items-center justify-center',
    logoWrapperCls: 'w-52 h-28 m-auto flex items-center justify-center mt-24',
    logoCls: 'h-full',
    formCls: 'w-full max-w-md m-auto flex flex-col justify-center items-center',
    formHeaderCls: 'relative w-full mb-10',
    labelCls: 'font-bold',

    title: 'text-2xl font-semibold mx-2',
    border: 'w-12 h-1.5 my-1 bg-blue-400 border-0 mx-2',
    actionBtn: 'w-full flex justify-end items-center px-2',
    rightPanel: 'w-6/12 h-full flex justify-center items-center'
  },

  // Map Marker Card
  mapMarkerCard: {
    baseCls: '',
    locationCls: 'pl-1 text-secondary-dark font-bold text-base',
    itemCls:
      'w-auto px-2 text-left text-sm capitalize border-8 border-white rounded',
    // Status
    defaultCls: 'bg-white text-secondary-dark',
    availableCls: 'bg-success text-white text-center',
    notSoAvailableCls: 'bg-warning text-white text-center',
    notAvailableCls: 'bg-danger text-white text-center'
  },

  // Page
  pageStyles: {
    base: 'w-full',
    header:
      'w-full h-auto flex justify-between items-center border-b border-gray-300',
    title:
      'w-auto py-3 px-4 flex items-center flex-none leading-none border-b-4 border-blue-400',
    searchAndFilter: 'w-full flex items-center my-4',
    dropdown: 'w-48 h-12 border flex justify-center items-center',
    filters: 'w-12 h-12 border flex justify-center items-center',
    search: 'flex-1 h-12 p-2 border flex items-center',
    tableWrapper: 'w-full min-h-full mb-4',
    pagination: 'flex-1 h-12 bg-gray-100 flex items-center'
  },

  // Member Details
  memberDetailsStyles: {
    header: 'w-full flex justify-between items-center',
    pagination: 'flex justify-between items-center',
    image: 'w-32 h-32',
    heading: 'font-medium text-xl',
    memberInfo: {
      wrapper: 'w-full flex justify-start items-center',
      flex: 'flex items-center w-full',
      textWrapper: 'w-full',
      nameWrapper: 'flex flex-1 flex-col items-start justify-evenly mr-4 ml-4',
      dateStyle: 'w-full ml-4 text-sm'
    }
  },

  // Record Shell Main Fields
  recordShellMainFieldsStyles: {
    base: 'w-full rs_mainfields_min-height flex flex-row p-r.5 space-x-r1.5 items-center',
    profileImgContainer: 'w-24 h-24 flex items-center flex-shrink-0',
    fieldsContainer: 'flex-1 flex flex-col justify-center',
    formWrapper: 'flex flex-col',
    formFieldWrapper: 'flex-1',
    dateCreationContainer: 'inline-flex',
    dateLabels: 'flex-1 pl-r.5 text-xs'
  },

  // Record Shell Toolbar
  recordShellToolbarStyles: {
    base: 'p-3 border border-gray-100 shadow'
  },

  // Reservation Details
  reservationDetailsStyles: {
    header: 'w-full flex justify-between items-center',
    pagination: 'flex justify-between items-center',
    image: 'w-28 h-28',
    heading: 'font-medium text-xl',
    reservationInfo: {
      wrapper: 'w-full flex justify-start items-center',
      infoWrapper: 'w-full ml-5 mt-5 flex flex-col items-start justify-end',
      dateWrapper: 'w-full mt-5 flex justify-between items-center',
      dateStyle: 'w-2/4',
      text: 'font-bold text-2xl'
    }
  },

  // Reservation Search
  reservationSearchStyles: {
    // baseCls: 'absolute top-0 w-full flex-1 z-10',
    baseCls: 'h-10 relative m-r.25 top-0 z-10',
    searchContainer: 'flex flex-1 items-center h-full',
    searchWrapper: 'w-auto h-full flex-1 flex items-center relative',
    indicatorNameCls: 'h-full flex p-2 bg-gray-200',
    iconCls: 'pr-1 text-gray-800',
    termsWrapper: 'flex flex-row flex-wrap items-center',
    avatarInPill:
      'inline-flex items-center justify-center bg-blue-600 rounded-full text-white uppercase m-1 w-7 h-7 mr-1.5 whitespace-nowrap',
    termsCls:
      'inline-flex items-center bg-blue-100 border border-blue-600 rounded-full text-xs text-blue-800 m-0.5 whitespace-nowrap',
    closeTermCls: 'mx-1 rounded-full hover:text-red-600',
    inputSearchCls: 'w-full flex-1 p-2 outline-none'
  },

  // SIDEBAR MENU
  sidebarSlotStyles: {
    baseCls:
      'relative h-full bg-white shadow transition duration-150', // overflow-hidden
    footerCls:
      'absolute bottom-0 left-0 w-full h-12 px-r.5 flex items-center justify-end bg-secondary-50 text-base',
    toggleBtnCls:
      'cursor-pointer outline-none w-auto h-8 px-r.5 flex items-center justify-center bg-primary hover:bg-primary-dark text-white text-lg rounded'
  },

  // Spinner
  spinnerStyle: {
    spinnerWrapperCls:
      'flex justify-center items-center flex-1 bg-white shadow m-r.25 p-5'
  },

  // TAB SLOT
  tabSlotStyles: {
    base: 'h-full overflow-hidden'
  },

  // Tabs
  tabStyles: {
    base: 'flex flex-row',
    sidemenu: 'w-1/6 flex flex-col',
    menu: 'flex-1 border border-black',
    details: 'w-full pl-10',
    detailsWrapper: 'w-1/2',
    rowsubDetails: 'flex-1 mb-4 pl-10',
    subDetails: 'mb-4',
    subDetailsLabel: 'font-medium text-sm',
    fieldsWrapper: 'pl-10',
    textInput: 'outline-black',
    flex: 'flex',
    flex1: 'flex-1'
  },

  // Reservation Tab
  tabResevationStyles: {
    base: 'flex flex-col w-full p-2'
  },

  // TextFields
  textFieldsStyles: {
    base: 'flex flex-row w-full p-2',
    textfield: 'flex-col border-b border-gray-500',
    labelStyle: 'text-xs text-gray-500',
    input: 'outline-none text-base font-normal'
  },
  // TimeoutView
  timeoutViewStyle: {
    base: 'absolute inset-1 flex items-center justify-center bg-white z-auto',
    content: 'flex flex-col items-center'
  },

  // TO DO: To be transfer in Paradise
  // Toaster
  toastStyle: {
    baseCls:
      'w-96 flex flex-row items-center p-r.5 m-r.5 text-base border rounded',
    successCls: 'bg-success-50 border-success text-success-dark',
    warningCls: 'bg-warning-50 border-warning text-warning-dark',
    errorCls: 'bg-danger-50 border-danger text-danger-dark',
    infoCls: 'bg-primary-50 border-primary-light text-primary',
    messageWrapperCls: 'flex-1 p-r.3 flex flex-col justify-start',
    titleCls: 'text-sm font-bold',
    messageCls: 'text-xs opacity-75',
    closeBtnCls:
      'cursor-pointer outline-none w-8 h-8 bg-transparent text-secondary hover:text-danger rounded-full'
  },

  // Toolbar
  toolbarStyles: {
    base: 'w-full space-x-2 flex justify-between items-center',
    toolbarWrapper: 'w-full flex justify-start items-center',
    buttonGroup: 'space-x-2 flex justify-start items-center'
  },

  // Coming Soon Page
  comingSoonStyle: {
    base: 'w-full h-96 flex justify-center items-center',
    title: 'text-5xl font-medium px-4 text-blue-500',
    right: 'flex flex-col justify-start px-4 border-gray-500',
    homeButton:
      'w-48 rounded-lg bg-blue-500 hover:bg-blue-700 text-white capitalize font-bold px-4 py-2'
  },

  // View Reservation in Main Content
  viewReservationMainContentStyle: {
    baseCls:
      'flex-1 p-r.5 flex flex-col des:flex-row space-x-0 des:space-x-r.5 space-y-r.5 des:space-y-0',
    fieldWrapperCls: 'w-full'
  },

  // View Reservation Summery
  viewReservationSummaryStyle: {
    baseCls: 'w-full p-r.25 mb-r.8 text-base text-secondary-dark',
    titleCls: 'font-semibold text-secondary-dark',
    noneSelectedCls: 'text-secondary-light',
    contentCls: 'py-r.25 text-secondary'
  }
}
