import { ICustomActionMap, IBootAppModeEvent } from './types'

export const CUSTOM_ACTION_MAP: ICustomActionMap = {
  bootAppMode: ({ event }) => {
    const { payload } = event as IBootAppModeEvent

    window.open(
      payload,
      'Popup',
      'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1280, height=720, top=30,'
    )
  }
}
