/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useCallback, useEffect, Suspense } from 'react'
import { Switch, useHistory } from 'react-router-dom'
import mitt from 'mitt'
import { DnaTailwind, RaySpinner, Spinner } from '@dna/paradise'
import { useWorkflow } from '@dna/hooks'
import generateRoutes from './getRoutes'
import {
  PlatformActionFunction,
  LoginParam,
  TCustomActionMap,
  IOpenAppModeParams,
  IContext
} from './types'
import { defaultThemes as GlobalTheme } from '../../../../browser/themes/default'
import { CUSTOM_ACTION_MAP } from './handlers'

const { HOSTNAME = 'instaprotek.localhost' } = process.env

const Bootloader: React.FC<unknown> = props => {
  /** Fix for server-side rendering */
  if (typeof window === 'undefined') {
    return <></>
  }

  const emitter = useMemo(() => mitt(), [])
  const history = useHistory()
  const workflow_type = 'WEB_BOOTLOADER'
  const session_token = new URLSearchParams(window.location.search).get('st')
  const app_mode = new URLSearchParams(window.location.search).get('app')
    ? true
    : false
  const [connection, workflow, send] = useWorkflow<IContext>({
    workflow_type,
    namespace: '/workflow/shell/bootloader',
    params: {
      host_location: window.location,
      session_token,
      user_agent: navigator.userAgent,
      default_hostname: HOSTNAME
    }
  })

  console.log({ workflow })

  const isActive = connection.matches('active')

  // Action Effect Handler
  const actionStateEffectHandler = () => {
    if (app_mode) return

    if (workflow?.matches('ready.authorized')) {
      if (workflow?.actions && workflow?.actions.length) {
        workflow?.actions.forEach(action_item => {
          if (!CUSTOM_ACTION_MAP[action_item.type as TCustomActionMap]) return

          CUSTOM_ACTION_MAP[action_item.type as TCustomActionMap]({
            props,
            context: workflow?.context,
            event: workflow?.event
          })
        })
      }
    }
  }

  useEffect(actionStateEffectHandler, [workflow?.actions, workflow?.event])

  const actionLogin: PlatformActionFunction<LoginParam> = useCallback(
    payload => {
      send({
        type: 'AUTHORIZED',
        payload
      })
    },
    [workflow?.value]
  )

  const actionLogout: PlatformActionFunction<undefined> = useCallback(() => {
    localStorage.clear()
    send({
      type: 'UNAUTHORIZED'
    })
  }, [workflow?.value])

  const generateSnapshotShareableLink = useCallback(() => {
    send({
      type: 'GENERATE_SNAPSHOT_LINK'
    })
  }, [workflow?.value])

  const openAppMode: PlatformActionFunction<IOpenAppModeParams> = useCallback(
    params => {
      send({
        type: 'OPEN_APP_MODE',
        payload: params
      })
    },
    [workflow?.value]
  )

  const routes = useMemo(
    () => workflow?.context.application_data.active_routes || [],
    [workflow?.context.application_data.active_routes]
  )

  useEffect(() => {
    if (!workflow?.context.application_data.shareable_link) return

    history.push(workflow?.context.application_data.shareable_link)
  }, [workflow?.context.application_data.shareable_link])

  if (!isActive) {
    return (
      // TODO : separate component
      <div
        style={{
          display: 'flex',
          height: '100vh',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
        {/* <Spinner /> */}
        <RaySpinner className='mb-1' />
        Connecting to server...
      </div>
    )
  }

  if (!connection.matches('active') || !workflow) return null

  const route_elements = generateRoutes({
    routes,
    actions: {
      actionLogin,
      actionLogout,
      generateSnapshotShareableLink,
      openAppMode
    },
    user_id: workflow?.context?.application_data?.user_id ?? '',
    session_workflows:
      workflow?.context?.application_data?.session_workflows ?? {},
    emitter: emitter,
    session_id: workflow?.context?.application_data?.session_id ?? '',
    hostname: workflow?.context?.params?.host_location?.hostname ?? '',
    company_role: workflow?.context?.application_data?.company_role,
    application_id:
      workflow.context.application_config?.application_id ??
      '34dd614e-525d-4dc1-8cbc-95a9e82b1a9c',
    // ! TODO: Hardcoding this for now, updates will be made for this later.

    // ! company_id nga nagamit pag last development
    // ! if dili kani gamiton, kay dli ni mao giexpect sa portal/bootlaoder
    // ! dili pa maupdate ang app config for portal shell kay issue dli mo reflect
    company_id: 'ee336a3d-8c6c-4994-a8ac-b0bdd392b240'

    // ! core-api-v2 company id for InstaProtek. unta.
    // company_id: '168abb58-921d-4b95-97c5-060608893017'
  })

  return (
    <DnaTailwind theme={GlobalTheme}>
      {/* TODO: Need to add fallback component for the suspend. */}
      <Suspense fallback={<></>}>
        <Switch>{route_elements}</Switch>
      </Suspense>
    </DnaTailwind>
  )
}

export default Bootloader
