import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

// FOR REACT-SIDE MOCK ONLY
// import translation_en from '../public/locales/en/translation.json'
// import translation_de from '../public/locales/de/translation.json'

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    preload: ['en'],
    ns: ['translation'],
    defaultNS: 'translation',
    react: {
      useSuspense: false,
      wait: true
    },

    resources: {
      //   en: {
      //     translation: {
      //       description: {
      //         part1: 'Edit <1>src/App.js</1> and save to reload.',
      //         part2: 'Learn React'
      //       },
      //       counter: 'Changed language just once',
      //       counter_plural: 'Changed language already {{count}} times'
      //     }
      //   }
      //   en: {
      //     translation: translation_en
      //   },
      //   de: {
      //     translation: translation_de
      //   }
    }
  })

export default i18n
